/* global $ */

import {
    buildConversionData,
    buildPreConversionData,
    isSkyscanner,
    sendConversionEvent,
    sendPreConversionEvent
} from "./skyscanner";

$(function () {
    if(!isSkyscanner()) return false;

    const currency = document.getElementsByTagName('body')[0].getAttribute('currency');

    // step2 page -> pre conversion event
    const step2 = document.getElementById("step-2-data");
    if (step2) {
        const payload = buildPreConversionData(
            currency,
            step2.getAttribute("data-fare-basis-codes"),
            step2.getAttribute("data-marketing-carriers"),
            document.getElementsByClassName('total-amount')[0].innerText,
            "booking-page"
        );

        return sendPreConversionEvent(payload);
    }

    // booking page -> conversion event
    const booking = document.getElementById("booking-information");
    if (booking) {
        const payload = buildConversionData(
            booking.getAttribute("data-adult-number"),
            booking.getAttribute("data-cabin-class"),
            booking.getAttribute("data-child-number"),
            booking.getAttribute("data-conversion-id"),
            currency,
            booking.getAttribute("data-fare-basis-codes"),
            booking.getAttribute("data-flight-fare"),
            booking.getAttribute("data-infant-number"),
            booking.getAttribute("data-legs"),
            booking.getAttribute("data-marketing-carriers"),
            booking.getAttribute("data-payment-method"),
            booking.getAttribute("data-route-type"),
            booking.getAttribute("data-transaction-total")
        );

        sendConversionEvent(payload);
    }

    // itinerary listing (step1) -> pre conversion event
    const step1 = document.getElementsByClassName("top_step_1")[0];
    if (step1) {
        const payload = buildPreConversionData(
            currency,
            '',
            '',
            '0',
            'dayview-page'
        );

        return sendPreConversionEvent(payload);
    }

    // homepage -> pre conversion event
    const homepage = document.getElementsByClassName("page_front")[0];
    if (homepage) {
        const payload = buildPreConversionData(
            currency,
            '',
            '',
            '0',
            'home-page'
        );

        return sendPreConversionEvent(payload);
    }
});
